export default function () {
  const router = useRouter()

  const loadFromQueryString = (
    fields?: string[]
  ): Record<string, string | string[]> => {
    const route = router.currentRoute
    const query = route.value.query

    if (!query || !fields || fields.length === 0) return {}

    return fields?.reduce((obj, k) => ({ ...obj, [k]: query[k] }), {})
  }

  const loadFromQueryStringTree = (
    fields?: string[]
  ): { [key: string]: { [key: string]: boolean } } => {
    const route = router.currentRoute
    const query = route.value.query

    if (!query || !fields || fields.length === 0) return {}
    const tree: { [key: string]: { [key: string]: boolean } } = {}
    for (const key of fields) {
      if (!tree[key] && query[key]) tree[key] = {}
      if (typeof query[key] === 'string') tree[key][query[key]] = true
      if (Array.isArray(query[key])) {
        query[key].forEach((val: unknown) => {
          if (typeof val === 'string') tree[key][val] = true
        })
      }
    }
    return tree
  }

  const saveToQueryString = (
    data: Record<string, string | string[] | undefined>
  ) => {
    const route = router.currentRoute

    const query = {
      ...route.value.query,
      ...data
    }

    return router.replace({ query })
  }

  const overwriteQueryString = (query: Record<string, string | string[]>) => {
    return router.replace({ query })
  }

  function computedQueryFlag(name: string) {
    const route = router.currentRoute

    return computed<boolean>({
      get() {
        return route.value.query?.[name]
          ? route.value.query?.[name] === 'true'
          : false
      },
      set(val: boolean) {
        const query = {
          ...route.value.query,
          [name]: val.toString()
        }

        router.replace({ query })
      }
    })
  }

  return {
    loadFromQueryString,
    saveToQueryString,
    loadFromQueryStringTree,
    overwriteQueryString,
    computedQueryFlag
  }
}
